import axios from 'axios'
import i18n from "../i18n";
import { mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            items: [],
            modules: {
                EMPLOYEES: "employees",
                LOCATIONS: "locations",
                CATEGORIES: "categories",
                INCIDENCES: "incidences",
            },
            itemsPerPage: {
                label: "Results",
                values: [10, 20, 50, 100],
            },
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            totalPages: 0,
            search: '',
            loading: false
        }
    },
    computed: {
        ...mapState({
            token: state => state.token,
        }),
        isEditing() {
            return Boolean(this.$route.params.id);
        }
    },
    methods: {
        getRowCount(items) {
            return items.length;
        },
        getRecords(code) {
            axios.get(`api/${this.modules[code]}`)
                .then(response => {
                    this.items = response.data.data;
                }).catch(error => {
                    if (error.response.status === 401) {
                        this.$router.push({ path: '/login' });
                    } else {
                        this.$toast.error(error);
                    }
                });
        },
        updatePerPage(val) {
            this.perPage = val;
        },
        createRecord(code, data) {
            if (this.isEditing) {
                axios.put(`api/${this.modules[code]}/${this.$route.params.id}`, data)
                    .then(() => {
                        this.$router.push(`/${this.modules[code]}`);
                        this.$toast.success(i18n.t('success_modify'));
                    }).catch(error => {
                        if (error.response.status === 401) {
                            this.$router.push({ path: '/login' });
                        } else {
                            this.$toast.error(error);
                        }
                    });
            } else {
                axios.post(`api/${this.modules[code]}`, data)
                    .then(response => {
                        this.items = response.data;
                        this.$router.push(`/${this.modules[code]}`);
                        this.$toast.success(i18n.t('success_create'));
                    }).catch(error => {
                        let message = "";
                        if (error.response.status === 401) {
                            this.$router.push({ path: '/login' });
                        } else {
                            if (error.response.data.errors) {
                                for (let key in error.response.data.errors) {
                                    if (error.response.data.errors.hasOwnProperty(key)) {
                                        message += error.response.data.errors[key][0] + '  ';
                                    }
                                }
                            } else {
                                message = error.response.data.message;
                            }
                            this.$toast.error(message);
                        }
                    });
            }
        },
        createWithFilesRecord(code, data) {
            if (this.isEditing) {
                axios.post(`api/${this.modules[code]}/${this.$route.params.id}`, 
                    data,            
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                    .then(() => {
                        this.$router.push(`/${this.modules[code]}`);
                        this.$toast.success(i18n.t('success_modify'));
                    }).catch(error => {
                        if (error.response.status === 401) {
                            this.$router.push({ path: '/login' });
                        } else {
                            this.$toast.error(error);
                        }
                    });
            } else {
                axios.post(`api/${this.modules[code]}`, 
                    data,            
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                    .then(response => {
                        this.items = response.data;
                        this.$router.push(`/${this.modules[code]}`);
                        this.$toast.success(i18n.t('success_create'));
                    }).catch(error => {
                        let message = "";
                        if (error.response.status === 401) {
                            this.$router.push({ path: '/login' });
                        } else {
                            if (error.response.data.errors) {
                                for (let key in error.response.data.errors) {
                                    if (error.response.data.errors.hasOwnProperty(key)) {
                                        message += error.response.data.errors[key][0] + '  ';
                                    }
                                }
                            } else {
                                message = error.response.data.message;
                            }
                            this.$toast.error(message);
                        }
                    });
            }
        },
        modifyRecord(code, id) {
            this.$router.push(`/${this.modules[code]}/${id}/edit`);
        },
        deleteRecord(code, id) {
            this.$confirm(i18n.t('are_sure')).then(() => {
                axios.delete(`api/${this.modules[code]}/${id}`)
                    .then(() => {
                        this.getRecords(code);
                        this.$toast.success(i18n.t('success_delete'));
                    }).catch(error => {
                        if (error.response.status === 401) {
                            this.$router.push({ path: '/login' });
                        } else {
                            this.$toast.error(error);
                        }
                    });
            }).catch(() => {
            });
        }
    }
};
